import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn, TransactionHistoryParams } from 'src/@types/search-params';
import { getDetailBusinessProfile } from 'src/api/business_profile';
import { getBalance, getTransactionHistory } from 'src/api/localpay';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import TableContent from 'src/components/CTable';
import { useAppDispatch } from 'src/redux/hook';
import { setModalState } from 'src/redux/slice/modal.store';
import { cookCode } from 'src/shared/common/userProfile';
import { TransactionHistoryStatus, TransactionHistoryType } from 'src/shared/enum/localpay';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';
import { exportExcel, formatDateTime, formatMoney, parseQueryString } from 'src/utils';

interface FormData {
  status?: string;
  dateRange?: [Moment, Moment];
  code?: string;
  type?: string;
}
const listStatus = [
  { name: 'Tất cả', value: '-1' },
  { name: 'Pending', value: TransactionHistoryStatus.Pending },
  { name: 'Fail', value: TransactionHistoryStatus.Failed },
  { name: 'Success', value: TransactionHistoryStatus.Success },
];
const listType = [
  { name: 'Tất cả', value: '-1' },
  { name: 'Nạp tiền ví LCS App', value: TransactionHistoryType.Recharge },
  { name: 'Nạp tiền ví Asim Portal', value: TransactionHistoryType.RechargeAsimPortal },
  { name: 'Nạp tiền vào tài khoản', value: TransactionHistoryType.LocalRecharge },
  { name: 'Cộng tiền hoa hồng NPP', value: TransactionHistoryType.Commission },
  { name: 'Hoàn tiền do lỗi gd', value: TransactionHistoryType.Refund },
  { name: 'Trả thưởng CTV', value: TransactionHistoryType.Reward },
  { name: 'Trừ tiền gán gói', value: TransactionHistoryType.RegisterPackage },
  { name: 'Trừ tiền đổi SIM', value: TransactionHistoryType.ChangeSim },
  { name: 'Trừ tiền đổi gói', value: TransactionHistoryType.ChangePackage },
  { name: 'Trừ tiền do nạp thừa', value: TransactionHistoryType.Excess },
  { name: 'Trừ tiền do nạp sai tài khoản', value: TransactionHistoryType.Mistake },
  { name: 'Trừ tiền chung', value: TransactionHistoryType.Paid },
  { name: 'Trừ tiền Topup', value: TransactionHistoryType.PaidTopup },
];

const LocalPay = () => {
  const dispatch = useAppDispatch();
  const [balance, setBalance] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TransactionHistory[]>([]);
  const [enterpriseProfile, setEnterpriseProfile] = useState<{ id: string; name: string }>();
  const [sortColumn, setSortColumn] = useState<ISortColumn>();
  const [, setSearchParams] = useSearchParams();

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= moment().endOf('day');
  };

  const renderStatus = (data: TransactionHistory) => {
    switch (data.status) {
      case TransactionHistoryStatus.Success:
        return (
          <div className='flex items-center justify-center'>
            <div className='px-4 py-1 text-white bg-green-500 w-fit rounded-md'>Success</div>
          </div>
        );
      case TransactionHistoryStatus.Pending:
        return (
          <div className='flex items-center justify-center'>
            <div className='px-4 py-1 text-white bg-orange-500 w-fit rounded-md'>Pending</div>
          </div>
        );
      default:
        return (
          <div className='flex items-center justify-center'>
            <div className='px-4 py-1 text-white bg-red-500 w-fit rounded-md'>Fail</div>
          </div>
        );
    }
  };
  const renderType = (data: TransactionHistory) => {
    switch (data.type) {
      // case TransactionHistoryType.Active:
      //   return <span>Kisch h</span>;
      case TransactionHistoryType.Topup:
        return <span>Nạp tiền ví LCS App</span>;
      case TransactionHistoryType.Recharge:
        return <span>Nạp tiền ví LCS App</span>;
      case TransactionHistoryType.LocalRecharge:
        return <span>Nạp tiền vào tài khoản</span>;
      case TransactionHistoryType.Commission:
        return <span>Cộng tiền hoa hồng NPP</span>;
      case TransactionHistoryType.Refund:
        return <span>Hoàn tiền do lỗi gd</span>;
      case TransactionHistoryType.Reward:
        return <span>Trả thưởng CTV</span>;
      case TransactionHistoryType.RechargeAsimPortal:
        return <span>Nạp tiền ví Asim Portal</span>;
      case TransactionHistoryType.ChangeSim:
        return <span>Trừ tiền đổi SIM</span>;
      case TransactionHistoryType.RegisterPackage:
        return <span>Trừ tiền gán gói</span>;
      case TransactionHistoryType.ChangePackage:
        return <span>Trừ tiền đổi gói</span>;
      case TransactionHistoryType.Excess:
        return <span>Trừ tiền do nạp thừa</span>;
      case TransactionHistoryType.Mistake:
        return <span>Trừ tiền do nạp sai tài khoản</span>;
      case TransactionHistoryType.Paid:
        return <span>Trừ tiền chung</span>;
      case TransactionHistoryType.PaidTopup:
        return <span>Trừ tiền Topup</span>;
      default:
        return <span>Nạp tiền ví</span>;
    }
  };

  const renderDate = (date: string) => {
    return <div>{date ? formatDateTime(date) : ''}</div>;
  };

  const renderAction = (data: TransactionHistory) => {
    return (
      <button
        onClick={() =>
          dispatch(
            setModalState({ open: true, content: 'top-up', selectedHistory: data, width: '70%' }),
          )
        }
        className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
      >
        Xem chi tiết
      </button>
    );
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', align: 'center', isHideSort: true, width: 20 },
    { name: 'Mã DN', fieldName: 'merchantId', align: 'center', isHideSort: true },
    {
      name: 'Mã ví Localpay',
      fieldName: 'accountNumber',
      align: 'center',
      isHideSort: true,
    },
    { name: 'Mã giao dịch', fieldName: 'transactionId', isHideSort: false, width: 130 },
    {
      name: 'Loại',
      fieldName: 'type',
      isHideSort: false,
      align: 'center',
      renderSpecialCard: renderType,
      width: 50,
    },
    {
      name: 'Số tiền',
      fieldName: 'amount',
      isHideSort: true,
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>
          {data.type === TransactionHistoryType.Topup ||
          data.type === TransactionHistoryType.Recharge ||
          data.type === TransactionHistoryType.RechargeAsimPortal ||
          data.type === TransactionHistoryType.LocalRecharge ||
          data.type === TransactionHistoryType.Commission ||
          data.type === TransactionHistoryType.Refund ||
          data.type === TransactionHistoryType.Reward
            ? '+'
            : '-'}
          {formatMoney(data.amount, 'đ')}
        </span>
      ),
    },
    {
      name: 'Số dư sau giao dịch',
      fieldName: 'balance',
      isHideSort: true,
      renderSpecialCard: (data) => formatMoney(data.balance, 'đ'),
      width: 150,
    },
    {
      name: 'Trạng thái',
      fieldName: 'status',
      isHideSort: true,
      editable: true,
      renderSpecialCard: renderStatus,
    },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      isHideSort: false,
      renderSpecialCard: (data) => renderDate(data.createdAt),
    },
    {
      name: 'Người tạo',
      fieldName: 'createdBy',
      isHideSort: false,
    },
    { name: 'Thao tác', fieldName: 'id', renderSpecialCard: renderAction, isHideSort: true },
  ];

  const userStore = localStorage.getItem('merchantInfo');
  let merchantInfo: any;
  if (userStore !== null) {
    try {
      merchantInfo = JSON.parse(userStore);
    } catch (error) {
      console.log(error);
    }
  }

  const renderOptions = (listOpts: any[]) => {
    return listOpts.map((item) => {
      return (
        <Select.Option value={item.value} key={item.value}>
          {item.name}
        </Select.Option>
      );
    });
  };

  const handleSearch = (formValues: FormData) => {
    const { status, code, dateRange, type } = formValues;
    const searchParams = queryString.stringify({
      status: status === '-1' ? undefined : status,
      type: type === '-1' ? undefined : type,
      code,
      dateFrom: dateRange ? dateRange[0].format('YYYY-MM-DD') : undefined,
      dateTo: dateRange ? dateRange[1].format('YYYY-MM-DD') : undefined,
    });
    setSearchParams(searchParams);
  };
  const handleExport = () => {
    setLoading(true);
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `lich_su_giao_dich_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const pagination = parseQueryString(location.search);
    delete pagination.pageIndex;
    delete pagination.pageSize;

    const params = queryString.stringify({ ...pagination, distributorId: merchantInfo.merchantId });
    const url = process.env.REACT_APP_API_URL + `/api/Provider/export-excel/localpay?${params}`;
    exportExcel(url)
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = async (searchParams: TransactionHistoryParams) => {
    const balanceRes = await getBalance();
    if (balanceRes) {
      setBalance(balanceRes.balance);
    }
    const transactionHistoryRes = await getTransactionHistory(
      searchParams,
      merchantInfo.merchantId,
    );
    const res = await getDetailBusinessProfile();
    if (transactionHistoryRes) {
      const temp: TransactionHistory[] = transactionHistoryRes.results.map(
        (item: any, index: number) => {
          return { ...item, key: index };
        },
      );
      if (res.account_profile_values) {
        res.account_profile_values.forEach(
          (element: { table_attribute_field_name: string; value: any }) => {
            if (element.table_attribute_field_name === 'enterpriseName') {
              setEnterpriseProfile({
                id: cookCode(res.id, 'DN'),
                name: element.value ? element.value : '',
              });
            }
          },
        );
      }
      setData(temp);

      setTotal(transactionHistoryRes.rowCount);
    }
  };

  useEffect(() => {
    const pagination = parseQueryString(location.search);

    fetchData({
      ...pagination,
      pageIndex: pagination.pageIndex ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
    setSortColumn(undefined);
  }, [location.search]);

  return (
    <>
      <BoxWithLabel title='Thông tin ví Localpay'>
        <div className='flex justify-start'>
          <Row gutter={[0, 24]} className='w-full text-left'>
            <Col span={12}>
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={6}>Mã DN</Col>
                <Col span={16}>{enterpriseProfile?.id}</Col>
                <Col span={6}>Mã ví</Col>
                <Col span={16}>{merchantInfo.payAccountId}</Col>
                <Col span={6}>Mã đại lý</Col>
                <Col span={16}>{merchantInfo.merchantId}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={6}>Tên DN</Col>
                <Col span={16}>{enterpriseProfile?.name}</Col>
                <Col span={6}>Ngày đăng kí</Col>
                <Col span={16}>{moment(merchantInfo.createdAt).format('DD/MM/YYYY')}</Col>
              </Row>
            </Col>{' '}
            <Col span={12} className='flex items-center text-2xl font-bold'>
              Số dư hiện tại: {formatMoney(balance, 'đ')}
            </Col>
            {/* <Col span={12}>
              <Button disabled>Nạp tiền vào ví</Button>
            </Col> */}
          </Row>
        </div>
      </BoxWithLabel>
      <BoxWithLabel classNames='mt-8' title='Lịch sử giao dịch'>
        <Form
          onFinish={handleSearch}
          initialValues={{ status: '-1', type: '-1' }}
          layout='inline'
          className='gap-2'
        >
          <Form.Item name='code'>
            <Input
              className='min-w-[150px]'
              prefix={<SearchOutlined />}
              placeholder='Mã giao dịch'
            />
          </Form.Item>
          <Form.Item name='dateRange' label='Thời gian tạo'>
            <DatePicker.RangePicker
              disabledDate={disabledDate}
              placeholder={['Từ ngày', 'đến ngày']}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
            />
          </Form.Item>
          <Form.Item name='status' label='Trạng thái'>
            <Select showSearch>{renderOptions(listStatus)}</Select>
          </Form.Item>
          <Form.Item name='type' label='Loại giao dịch'>
            <Select showSearch className='min-w-[200px]'>
              {renderOptions(listType)}
            </Select>
          </Form.Item>
          <Form.Item className={'ml-auto'}>
            <Button htmlType='submit'>Tìm kiếm</Button>
            <Button loading={loading} type='primary' onClick={handleExport} className='ml-2'>
              Export
            </Button>
          </Form.Item>
        </Form>
        <div className='mt-8'>
          <TableContent
            locale={{ emptyText: 'Không tìm thấy kết quả' }}
            totalRecord={total}
            dataSource={data}
            columns={columns}
            sortColumn={sortColumn}
            onSort={(sort: ISortColumn) => {
              const pagination = parseQueryString(location.search);
              fetchData({
                ...pagination,
                pageIndex: pagination.pageIndex ?? 1,
                pageSize: pagination.pageSize ?? 10,
                sortField: sort.fieldName,
                asc:
                  sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? false : undefined,
              });
            }}
          />
        </div>
      </BoxWithLabel>
    </>
  );
};

export default LocalPay;

import { message } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../redux';
import { setGlobalState } from '../redux/slice/globalSlice';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { parseJwt } from 'src/utils';
dayjs.extend(utc);

// const instance = axios.create();
type Method = 'get' | 'post' | 'delete' | 'put';

const axiosInstance = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_API_URL,
});
// instance.defaults.baseURL = API_URL;

const { requestId, deviceId } = store.getState().global;

axiosInstance.interceptors.request.use(
  (config) => {
    store.dispatch(setGlobalState({ loading: true }));
    const accessToken = localStorage.getItem('token');

    if (accessToken) {
      config.data = config.data ? config.data : {};
      config.headers!.Authorization = 'Bearer ' + accessToken;
    }
    if (config && config.headers) {
      config.headers!.deviceId = deviceId;
      config.headers!.requestId = requestId;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

async function refreshAccessToken({ originalRequest }: { originalRequest: any }) {
  const tokenType = localStorage.getItem('tokenType');
  const refreshToken = localStorage.getItem('refreshToken');

  const accessToken = localStorage.getItem('token');
  const accountId = parseJwt(accessToken)?.sub;

  try {
    axios
      .get(`${process.env.REACT_APP_AUTH_TELCO_NPP_URL}/refresh-token?accountId=${accountId}`, {
        headers: {
          Authorization: tokenType + ' ' + refreshToken?.trim(),
          requestId,
          deviceId,
        },
      })
      .then(async (res) => {
        let newAccessToken = '';
        if (res?.data?.response) {
          if (res?.data?.response?.technicalCode === 'S200') {
            newAccessToken = res.data.response.accessToken;
          } else {
            localStorage.clear();
            message.error('Phiên đăng nhập hết hạn');
            window.location.replace('/login');
          }
        } else {
          axios
            .get(
              `${process.env.REACT_APP_AUTH_SSO_URL}/auth-service/v1/refresh-token?accountId=${accountId}`,
              {
                headers: {
                  Authorization: tokenType + ' ' + refreshToken?.trim(),
                  requestId,
                  deviceId,
                },
              },
            )
            .then((res) => {
              if (res?.data?.response?.technicalCode === 'S200') {
                newAccessToken = res.data.response.accessToken;
              } else {
                localStorage.clear();
                message.error('Phiên đăng nhập hết hạn');
                window.location.replace('/login');
              }
            });
        }
        const expiresTime = dayjs.unix(parseJwt(newAccessToken)?.exp);

        localStorage.setItem('token', newAccessToken);
        localStorage.setItem('expiresTime', expiresTime.format('DD-MM-YYYY HH:mm:ss'));
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        // return axios(originalRequest);
        try {
          const response = await axios.request(originalRequest);
          store.dispatch(setGlobalState({ loading: false }));
          return await Promise.resolve(response?.data);
        } catch (err) {
          return await Promise.reject(err);
        }
      });
  } catch (error) {
    console.error('Error refreshing access token:', error);
    throw error;
  }
}

axiosInstance.interceptors.response.use(
  function (response) {
    store.dispatch(setGlobalState({ loading: false }));

    return response.data;
  },
  async function (error) {
    store.dispatch(setGlobalState({ loading: false }));
    if (error.response?.status === 401) {
      const expiresTime = parseJwt(localStorage.getItem('token'))?.exp;
      const now = dayjs().unix();
      const isExpired = now > expiresTime;
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken && isExpired) {
        try {
          const newResponse = await refreshAccessToken({
            originalRequest: error.config,
          });
          return newResponse;
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }

      localStorage.clear();
      message.error('Phiên đăng nhập hết hạn');
      window.location.replace('/login');
      return null;
    }
    if (
      error.response?.data?.errorMessage?.includes('Bạn đang có yêu cầu hoàn tiền - trả thưởng')
    ) {
      store.dispatch(
        setGlobalState({
          contentModal: 'Vui lòng đợi yêu cầu được hoàn thành',
          titleModal: 'BẠN ĐANG CÓ YÊU CẦU HOÀN TIỀN - TRẢ THƯỞNG',
          visibleModal: true,
        }),
      );
    } else if (error.response?.data?.errorMessage?.includes('Số dư tài khoản có thay đổi.')) {
      store.dispatch(
        setGlobalState({
          contentModal: 'Số dư tài khoản có thay đổi',
          titleModal: 'Thông báo lỗi',
          visibleModal: true,
        }),
      );
    } else if (
      error.response?.data?.errorMessage?.includes(
        'Gói nethub đang được áp dụng trên chính sách số đẹp hiện tại. Vui lòng kiểm tra lại',
      )
    ) {
      store.dispatch(
        setGlobalState({
          contentModalWithIcon:
            'Gói Nethub đang được áp dụng trên chính sách số đẹp hiện tại. Vui lòng kiểm tra lại',
          titleModalWithIcon: 'Thông báo!',
          visibleModalWithIcon: true,
        }),
      );
    } else if (
      error.response?.data?.errorMessage ===
        'Object reference not set to an instance of an object.' ||
      error.response?.data?.errorMessage.includes('cannot') ||
      error.response?.data?.errorMessage.includes('exception')
    ) {
      message.error('Lỗi hệ thống! Vui lòng thử lại sau');
    } else {
      if (error.message === 'timeout of 60000ms exceeded') {
        /* empty */
      } else if (error.response?.data?.errorMessage) {
        message.error(`${error.response?.data?.errorMessage}`);
        // eslint-disable-next-line no-empty
      } else {
        message.error('Lỗi hệ thống! Vui lòng thử lại sau');
      }
    }
    console.log('error', Promise.reject(error));
    return Promise.reject(error);
  },
);
export type Response<T = any> = {
  [x: string]: any;

  isSucceeded: boolean;
  data: T;
};

export type IResponseApi<T = any> = Promise<Response<T>>;

/**
 *
 * @param method - Request methods
 * @param url - Request url
 * @param data - Request data or params
 * @param config - Config of axios request
 */
export const request = <T = any>(
  method: Method,
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): IResponseApi<T> => {
  let params = {};
  if (data) {
    Object.keys(data).forEach((key) => {
      let value = data[key];

      if (typeof value === 'string') {
        value = value.trim();
      }

      data[key] = value;

      if ([null, '', undefined].includes(value)) {
        delete data[key];
      }
    });
  }

  if (method === 'get') {
    params = data;
  }

  return axiosInstance({
    method,
    url,
    data,
    params,
    ...config,
  }) as any;
};
export default request;

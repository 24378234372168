import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { IOverFlowOrderDetailResponse } from 'src/shared/interface/connection';

export interface ConnectionState {
  loading: boolean;
  dataSuccess: IOverFlowOrderDetailResponse[];
  dataFailed: IOverFlowOrderDetailResponse[];
}
const initialState: ConnectionState = {
  loading: false,
  dataSuccess: [],
  dataFailed: [],
};
export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setConnectionState(state, action: PayloadAction<Partial<ConnectionState>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: connectionAction, reducer: connectionReducer } = connectionSlice;
export const { setConnectionState } = connectionSlice.actions;

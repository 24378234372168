export enum TransactionHistoryStatus {
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Error = 'ERROR',
  Failed = 'FAILED',
}
export enum TransactionHistoryType {
  Topup = 'Topup',
  Recharge = 'Recharge',
  LocalRecharge = 'LocalRecharge',
  Commission = 'Commission',
  Refund = 'Refund',
  Reward = 'Reward',
  RechargeAsimPortal = 'RechargeAsimPortal',
  Active = 'Active',
  ChangeSim = 'ChangeSim',
  RegisterPackage = 'RegisterPackage',
  ChangePackage = 'ChangePackage',
  Excess = 'Excess',
  Mistake = 'Mistake',
  Paid = 'Paid',
  PaidTopup = 'PaidTopup',
}
